



































import { Vue, Component } from 'vue-property-decorator'
import { CommonMudule } from '@/store/modules/common'
import { cloneDeep } from 'lodash'

@Component({
  name: 'portfolio',
  components: {
  }
})
export default class extends Vue {
  activeIndex = 0
  listPre = [
    {
      url: require('@/assets/images/main/1.png'),
      title: '财富体检',
      msg: '为您的家庭财务状况做深度体检。了解您的家庭资产财务状况，为资产配置做好充分准备。',
      page: '/analys/index',
      type: 'analys',
      offType: 'assetAnalysis'
    }, {
      url: require('@/assets/images/main/2.png'),
      title: '投资组合规划',
      msg: '基于您家庭财务状况和财富目标，量身定制专属投资计划。',
      page: '/portfolio/index',
      offType: 'invest'
    }, {
      url: require('@/assets/images/main/3.png'),
      title: '家庭基础保障规划',
      msg: '学会使用“杠杆”为自己的小家保驾护航。只需要一小部分的资金安排，即可为家庭撑起完美保护伞。',
      page: '/basicSecurity/index',
    }, {
      url: require('@/assets/images/main/4.png'),
      title: '子女教育规划',
      msg: '子女的教育问题是从孩子出生开始就困扰的问题。一切的不确定性中，有力的经济支持是重要且确定的。提前做好准备，免除后顾之忧！',
      page: '/childTeach/index',
      offType: 'childEdu'
    }, {
      url: require('@/assets/images/main/5.png'),
      title: '退休养老规划',
      msg: '养老金到底多少才算够？科学建模，系统精密计算，帮您未雨绸缪，少走弯路。',
      page: '/pension/index',
      offType: 'pension'
    }, {
      url: require('@/assets/images/main/6.png'),
      title: '资产配置策略指引',
      msg: '由海内外资产管理行业顶级专家顾问提供重磅的年度观点和实操方案。对各主要资产类别提供了科学的配置比例建议。',
      page: '/instituteConfig/index',
    }, {
      url: require('@/assets/images/main/8.jpg'),
      title: '财富聚焦',
      msg: '投资理念决定了您的投资模式、心态和长期收益。拥有正确的投资理念，能发现更多的投资机会，从而赢得更多的财富。',
      page: '/otherOpt/index',
      offType: 'wealth',
    }, {
      url: require('@/assets/images/main/7.png'),
      title: '下载建议书',
      msg: '基于家庭所有情况生成全资产配置系列报告，覆盖全阶段财富需求。个性化专家定制，一键直接下载。',
      page: '/download/index',
    }, {
      url: require('@/assets/images/main/8.jpg'),
      title: '市场雷达',
      msg: '通过对各类经济指标以及市场热点信息的度量与提炼，帮助您快速把握市场脉络，先人一步发掘投资机遇。',
      page: '/sub-radar-board',
      type: 'marketRadar',
      offType: 'marketRadar'
    },
    {
      url: require('@/assets/images/main/9.jpg'),
      title: '房产看板',
      msg: '剖析全国及重点城市房产市场热点数据信息，帮助您对房产资产做出科学决策。',
      page: '/sub-house-board',
      offType: 'house'
    },
  ]
  list = [] as Array<any>
  mouseoverEvent(idx: number) {
    this.activeIndex = idx
  }
  mouseoutEvent() {
    this.activeIndex = 0
  }
  clickEvent(item: any) {
    this.$emit('clickEvent', item)
  }
  showInvest() {
    if (CommonMudule.authArr.length > 0) {
      const arr = cloneDeep(this.listPre)
      for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i].offType && CommonMudule.authArr.includes(arr[i].offType as string)) {
          arr.splice(i, 1)
        }
      }
      this.list = arr
    } else {
      this.list = this.listPre
    }
  }
  mounted() {
    this.showInvest()
  }
}
