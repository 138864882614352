










































































































import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { UserModule } from '../../store/modules/user'
import Accordion from './template/accordion.vue'

@Component({
  name: 'pension',
  components: {
    moduleHeader,
    Accordion
  }
})
export default class extends Vue {
  created() {
    UserModule.UserDetailAction()
  }
  clickEvent(item: any) {
    this.jumpToPage(item.page)
  }
  jumpToPage(url: string) {
    this.$router.push(url)
  }
  get systemData() {
    return UserModule.userInfo
  }
}
